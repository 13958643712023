import React from 'react';
import { Link } from 'gatsby';
import './breadcrumbs.css';

const Breadcrumbs = ({ crumbs = [] }) => {
  return (
    <nav className="breadcrumbs" aria-label="Breadcrumb">
      <ul>
        {crumbs.length > 0 ? (
          crumbs.map((crumb, index) => (
            <li
              key={crumb.path || index}
              aria-current={index === crumbs.length - 1 ? 'page' : undefined}
            >
              {index !== crumbs.length - 1 ? (
                <Link to={crumb.path}>{crumb.label}</Link>
              ) : (
                <span>{crumb.label}</span>
              )}
            </li>
          ))
        ) : (
          <li>Nenhuma navegação disponível</li>
        )}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
