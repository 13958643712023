import React from 'react';
import './suporte.css';
import Seo from '../components/Seo';
import Layout from '../components/layout';
import Breadcrumbs from '../components/Breadcrumbs/breadcrumbs';
import CallToActionSection from '../components/CallToActionSection/CallToActionSection';

const Support = () => {
  const crumbs = [
    { path: '/', label: 'Página Inicial' },
    { path: '/suporte', label: 'Centro de Suporte' }
  ];

  return (
    <Layout>
      <div className="support-container">
        <Seo title="Suporte | Prontuário Fácil" description="Encontre respostas, tutoriais, guias e atendimento personalizado no Centro de Suporte do Prontuário Fácil." />
        <Breadcrumbs crumbs={crumbs} />
        <main className="support-content">
          <h1 className='support-content'>Centro de Suporte</h1>
          <div className="support-sections">
            <section className="support-faq">
              <h2 className='support-content'>Perguntas Frequentes</h2>
              <ul>
                <li><a href="#faq1">Como faço para resetar minha senha?</a></li>
                <li><a href="#faq2">Onde posso encontrar informações sobre minha conta?</a></li>
              </ul>
            </section>
            <section className="support-guides">
              <h2 className='support-content'>Guias de Início Rápido</h2>
              <a href="/path/to/guide1.pdf" id="guia_rapido" download="Guia1.pdf">Download do Guia do Usuário</a>
            </section>
            <section className="support-tutorials">
              <h2>Vídeos Tutoriais</h2>
              <p>Assista aos nossos tutoriais para aprender rapidamente a usar o sistema.</p>
              {/* <iframe width="300" height="169" src="https://www.youtube.com/embed/VIDEO_ID" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
            </section>
            <section className="support-contact">
              <h2>Contato de Suporte</h2>
              <p>Para mais informações, contate-nos:</p>
              <p>Email: suporte@prontuariofacil.com</p>
              <p>Telefone: (11) 4002-8922</p>
            </section>
          </div>
        </main>
      </div>
      <CallToActionSection />
    </Layout>
  );
};

export default Support;
