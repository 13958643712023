import React from 'react';
import './CallToActionSection.css';
import { Link } from 'gatsby';

const CallToActionSection = () => {
    return (
        <section className="cta-section" aria-labelledby="cta-title" aria-describedby="cta-description">
            <div className="cta-content">
                <h2 id="cta-title" className="cta-title">Pronto para Transformar a Gestão do seu Consultório ou Clínica?</h2>
                <p id="cta-description" className="cta-description">
                    Descubra como o Prontuário Fácil pode fazer a diferença na sua prática médica.
                </p>
                <Link to="/novo-usuario" id="botao-cadastrar-conta" className="cta-button" aria-label="Cadastre-se agora no Prontuário Fácil">
                    Cadastre-se Agora
                </Link>
            </div>
        </section>
    );
};

export default CallToActionSection;
